import Vue from 'vue'
import Vuex from 'vuex'
import general from './modules/general'
import user_session from './modules/user_session'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logStart: false,
    account: null,
    msalConfig: {
      auth: {
        clientId: 'cc3b16fd-f122-4736-b507-ca01daf27d18',
        authority:
          'https://login.microsoftonline.com/73ce310c-a2a2-40aa-b886-80082ef18625', //79ce310c-a2a2-40aa-b886-80082ef18625
        redirectUri: 'https://fraktalwes.com/',
        postLogoutRedirectUri: 'https://fraktalwes.com/'
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    },
    accessToken: '',
    locale: null
  },
  mutations: {
    setLocaleState(state, payload) {
      state.locale = payload;
      localStorage.setItem('locale', payload);
    },
    setLogStart(state, payload) {
      state.logStart = payload;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setAccount(state, payload) {
      state.account = payload;
      if (payload) {
        localStorage.setItem('msaccount', JSON.stringify(payload));
      } else {
        localStorage.removeItem('msaccount');
      }
    }
  },
  actions: {
    setMsAccount({ commit, dispatch }, payload) {
      commit('setAccount', payload);
      if (payload != null) {
        let data = {
          email: payload.username,
          key: payload.homeAccountId
        }
        dispatch('logInMS', data)
      }

    },
    setLocale({ commit }, payload) {
      commit('setLocaleState', payload)
    }

  },
  getters: {
    getLocale(state) {
      state.locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'es';
      return state.locale;
    },
    msAccount(state) {
      state.account = localStorage.getItem('msaccount') ? JSON.parse(localStorage.getItem('msaccount')) : null;
      return state.account;
    }
  },
  modules: {
    general,
    user_session
  }
})
