function isAuthenticated (state) {
    state.authenticated = localStorage.getItem('authenticated') ? true : false;
    return state.authenticated;
}

function getCurrentUser (state) {
    if (!state.currentUser) {
      state.currentUser = 
        localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')): null;
    }
    return state.currentUser;
}

function getError (state){
    return state.error
}
 
function isLoading (state) {
    return state.loading;
}

function currentProject(state) {
    if (!state.project) {
        state.project = 
        localStorage.getItem('currentProject') ? JSON.parse(localStorage.getItem('currentProject')): null;
    }
    return state.project;
}

function getMenu(state) {
    if (!state.menu) {
        state.menu = 
        localStorage.getItem('menuFK') ? JSON.parse(localStorage.getItem('menuFK')): null;
    }
    if (state.menu.length == 0){
        state.menu = 
        localStorage.getItem('menuFK') ? JSON.parse(localStorage.getItem('menuFK')): null;
    }
    return state.menu;
}


function getPolicyStatus(state) {
    if (!state.policyStatus || state.policyStatus.is_valid == true) {
        state.policyStatus = 
        localStorage.getItem('policyStatus') ? JSON.parse(localStorage.getItem('policyStatus')): null;
    }
    return state.policyStatus;
}

export default {
    isAuthenticated,
    getCurrentUser,
    getError,
    isLoading,
    currentProject,
    getMenu,
    getPolicyStatus
}