function setAuthenticated (state, payload) {
    state.authenticated = payload;
    if (payload) {
      localStorage.setItem('authenticated', payload);
    } else {
      localStorage.removeItem('authenticated');
    }
}

function setCurrentUser (state, payload) {
    state.currentUser = payload;
    if (payload) {
      localStorage.setItem('currentUser', JSON.stringify(payload));
    }
    else {
      localStorage.removeItem('currentUser');
    }
    
}
function setError (state, payload) {
  state.error = payload;
}


function increaseLoading (state) {
  state.loading += 1;
}
function decreaseLoading (state) {
  if (state.loading < 0) {
    state.loading == 0;
  } else {
    state.loading -= 1;
  }
}

function setCurrentProject (state, payload) {
  state.project = payload;
  if (payload) {
    localStorage.setItem('currentProject', JSON.stringify(payload));
  }
  else {
    localStorage.removeItem('currentProject');
  }
  
}

function setMenu (state, payload) {
  state.menu = payload;
  if (payload) {
    localStorage.setItem('menuFK', JSON.stringify(payload));
  }
  else {
    localStorage.removeItem('menuFK');
  }
  
}

function setPolicyStatus (state, payload) {
  state.policyStatus = payload == null ? { is_valid: true } : payload;
  if (payload) {
    localStorage.setItem('policyStatus', JSON.stringify(payload));
  }
  else {
    localStorage.removeItem('policyStatus');
  }
  
}

export default {
    setAuthenticated,
    setCurrentUser,
    setError,
    increaseLoading,
    decreaseLoading,
    setCurrentProject,
    setMenu,
    setPolicyStatus
}
  