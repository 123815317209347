import Vue from 'vue'
import Router from 'vue-router'

import { BOARD_ALIAS } from '../app_constants'

const Layout = () => import(/* webpackChunkName: "layout" */ '../components/shared/Layout.vue')
const Body = () => import(/* webpackChunkName: "body" */ '../components/shared/Body.vue')
const Home = () => import(/* webpackChunkName: "home" */ '../components/views/Home.vue')
const PWBIProject = () => import(/* webpackChunkName: "pwbi-project" */ '../components/views/pwbi/PWBIProject.vue')
const PWBI = () => import(/* webpackChunkName: "pwbi" */ '../components/views/pwbi/PWBI.vue')
const PWBIBoard = () => import(/* webpackChunkName: "pwbi-board" */ '../components/views/pwbi/PWBIBoard.vue')
const ChangePass = () => import(/* webpackChunkName: "change-pass" */ '../components/views/login/ChangePass.vue')
const UsersList = () => import(/* webpackChunkName: "users-list" */ '../components/views/users/UsersList.vue')
const ModiDevices = () => import(/* webpackChunkName: "modi-devices" */ '../components/views/modi/ModiDevices.vue')
const Modi = () => import(/* webpackChunkName: "modi" */ '../components/views/modi/Modi.vue')
const ModiGraph = () => import(/* webpackChunkName: "modi-graph" */ '../components/views/modi/ModiGraph.vue')
const ConfigView = () => import(/* webpackChunkName: "config-view" */ '../components/views/config/ConfigView.vue')
const NotFound = () => import(/* webpackChunkName: "not-found" */ '../components/shared/NotFound.vue')
const ProjectList = () => import(/* webpackChunkName: "project-list" */ '../components/views/project/ProjectList.vue')
const ProjectCreate = () => import(/* webpackChunkName: "project-create" */ '../components/views/project/ProjectCreate.vue')
const ProjectUpdate = () => import(/* webpackChunkName: "project-update" */ '../components/views/project/ProjectUpdate.vue')
const NotificationsConfig = () => import(/* webpackChunkName: "notifications-config" */ '../components/views/users/NotificationsConfig.vue')

const NPYMS = () => import(/* webpackChunkName: "npyps" */ '../components/views/custom_graphics/NPYMS.vue')

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Layout,
      children: [{
        path: '',
        component: Body,
        children: [
          /* { 
            path: '/:catchAll(.*)*', 
            name: 'NotFound', 
            component: NotFound 
          }, */
          {
            path: '',
            name: 'Home',
            component: Home,
          },
          {
            path: '/modi',
            name: 'ModiList',
            component: Modi,
          },
          {
            path: '/npyms',
            name: 'NPYMS',
            component: NPYMS,
          },
          {
            path: '/modi/create/:id?',
            name: 'ModiDevices',
            props: true,
            component: ModiDevices,
          },
          {
            path: '/modi/graph/:id/:modi',
            name: 'ModiGraph',
            component: ModiGraph,
          },
          {
            path: '/config',
            name: 'ConfigView',
            component: ConfigView,
          },
          {
            path: '/config/users',
            name: 'UserList',
            component: UsersList,
          },
          {
            path: '/config/project',
            name: 'ProjectList',
            component: ProjectList,
          },
          {
            path: '/config/project/create',
            name: 'ProjectCreate',
            component: ProjectCreate,
          },
          {
            path: '/config/project/update/:id',
            name: 'ProjectUpdate',
            props: true,
            component: ProjectUpdate,
          },
          {
            path: '/config/project/user/:projectId',
            name: 'ProjectUserList',
            props: true,
            component: UsersList,
          },
          {
            path: '/config/notifications',
            name: 'NotificationsConfig',
            component: NotificationsConfig,
          },
          {
            path: '/forgot',
            name: 'Forgot',
            component: ChangePass,
          },
          {
            path: '/project',
            name: 'Project',
            component: PWBIProject,
          },
          {
            path: '/project/:urip/:projName',
            name: 'Boards',
            component: PWBI,
          },
          {
            path: '/board/:label/:urir',
            name: BOARD_ALIAS,
            props: true,
            component: PWBIBoard,
          }
        ]
      }]
    }]
})
