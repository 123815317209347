export const URI = process.env.VUE_APP_URI

export const SERVICE_NAMES = {
    USER: 'fkl-user',
    LOGIN: 'fkl-auth/login',
    LOGINMS: 'fkl-auth/loginms',
    LOGOUT: 'fkl-auth/logout',
    PWBI: 'fkl-pwbi',
    BDO: 'fkl-bdo',
    MODI: 'fkl-modi',
    GENERAL: 'fkl-general',
    IMAGES: 'fkl-fimages',
    PROJECT: 'fkl-project',
    NOTIFICATION: 'fkl-notification',
}

export const HTTP_STATUS = {
    OK: 200,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    CONFLICT: 409,
    UNPROCESSABLE_ENTITY: 422,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    GATEWAY_TIMEOUT: 504,
    UNAVAILABLE_SERVICE: 503
}

export const SESSION_EXPIRATION = {
    IDLE_TIME: 15000, //15 seg -> 15 * 1000
    TIMEOUT: 3600000 //60 minutes -> 60 * 60 * 1000
}
export const EKEY = 'vFPPWninNy9NiCRuq9EkbRj9djW58aai'

export const BOARD_ALIAS = 'Board'

export const WATER = 'Agua'
export const GEOCHEMISTRY = 'Geoquímica'