import VAPI from '../../../http_common';
import { SERVICE_NAMES, HTTP_STATUS } from '../../../app_constants';

function getGeneral({ commit, state }) {
    commit('setGeneral', undefined)
    VAPI.get(`/${SERVICE_NAMES.GENERAL}/`).then((response)=>{
        commit('setGeneral', response.data.data)
    }).catch((e)=>{
        console.error("An error occurred loading general app data: " + e.toString())
    })

}

export default {
    getGeneral
}