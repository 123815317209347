export const ONEHOUR = 3600000

export const NOTIFICATION_PRIORITY = {
    1: {
        color: 'red',
        icon: 'flat-color-icons:high-priority',
        iconColor: 'white',
        name: 'notifications.high'
    },
    2: {
        color: 'orange',
        icon: 'flat-color-icons:medium-priority',
        iconColor: 'white',
        name: 'notifications.medium'
    },
    3: {
        color: 'green',
        icon: 'flat-color-icons:low-priority',
        iconColor: 'white',
        name: 'notifications.low',

    }
}

export const NOTIFICATION_METHOD_SEND = {
    SYSTEM : {
        text: 'notifications.system',
        value: 1
    },
    EMAIL : {
        text: 'notifications.email',
        value: 2
    },
    WHATSAPP : {
        text: 'notifications.whatsapp',
        value: 3
    },
}

export const CODES = {
    NO_VALID_TOKEN_CODE: 'FK-0001',
    USER_NOT_FOUND: 'FK-0002',
    USER_NOT_ACTIVE: 'FK-0003',
    USER_NOT_VERIFIED: 'FK-0004',
    USER_ALREADY_EXISTS: 'FK-0005',
    USER_ALREADY_VERIFIED: 'FK-0006',
    USER_ALREADY_ACTIVE: 'FK-0007',
    USER_ALREADY_INACTIVE: 'FK-0008',
    USER_ALREADY_LOGGED_IN: 'FK-0009',
    USER_ALREADY_LOGGED_OUT: 'FK-0010',
    USER_ALREADY_DELETED: 'FK-0011',
    USER_ALREADY_UPDATED: 'FK-0012',
    USER_ALREADY_CREATED: 'FK-0013',
    USER_ALREADY_BLOCKED: 'FK-0014',
    USER_ALREADY_UNBLOCKED: 'FK-0015',
    USER_ALREADY_VERIFIED_EMAIL: 'FK-0016',
    USER_ALREADY_VERIFIED_PHONE: 'FK-0017',
}